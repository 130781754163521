// scale3d(1.3, 1.3, 1) translate3d(11%, 0, 0) perspective(500px)
// scale3d(1.3, 1.3, 1) translate3d(0, 0, 0) perspective(500px)

@import "../../../assets/styles/mixins";

.posterPotrait {
  position: relative;
  height: 100%;
  float: left;
  width: calc((100vw - 20px) / 3);
  min-width: calc((100vw - 20px) / 3);
  max-width: calc((100vw - 20px) / 3);
  border-radius: $border-radius;
  transition: all 0.3s ease;

  button,
  a {
    &:focus,
    &:focus-within,
    &:active {
      box-shadow: 0 0 0 transparent;
      outline: none;
    }
  }

  .posterPotraitLink {
    display: block;
    float: left;
    width: 100%;
    border-radius: $border-radius;
    position: relative;
    padding: 0;
    cursor: pointer;
  }

  .posterThumbnail {
    float: left;
    width: 100%;
    position: relative;
    z-index: 2;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: calc(1.32821 * 100%);
    overflow: hidden;
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    background-color: var(--secondary);
    cursor: pointer;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .meta-list {
    .each-meta-item {
      font-size: $font-size-base - 0.2;
      padding-left: 7px;

      &::before {
        width: 2px;
        height: 2px;
        top: 7px;
      }
    }
  }

  .posterInfo {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    width: 100%;
    min-width: inherit;
    max-width: inherit;

    .posterInfo {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background: rgb(14, 13, 11);
      background: linear-gradient(
        0deg,
        rgba(14, 13, 11, 1) 50%,
        rgba(0, 0, 0, 0) 100%
      );
      color: white;
      z-index: 4;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      opacity: 0;
    }

    .posterInfoContent {
        float: left;
        width: calc(100% + 30px);
        transform: scale3d(0.8, 0.8, 1);
        margin-left: -15px;
        color: white;
        cursor: pointer;
        padding: 40px 10px 5px 10px;
      }

    .poster-title {
      font-size: $font-size-base ;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      text-align: left;
    }

    .poster-desc {
      font-size: $font-size-base - 0.17;
      max-height: 28px;
      overflow: hidden;
      -webkit-font-smoothing: antialiased;
      line-height: 1.1;
      margin-top: 4px;
      margin-bottom: 4px;
      text-align: left;
    }

    &:hover {
      transform: scale3d(1.2, 1.2, 1) translate3d(0, 0, 0) perspective(500px);
      z-index: 4;

      .posterThumbnail {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
      }

      .posterInfo {
        opacity: 1;

      }
    }
  }
}

.addWishListBlock {
  display: flex;
  align-items: center;
  padding: 0;
  transform: scale3d(0.8, 0.8, 1);
  width: calc(100% + 30px) !important;
  margin-left: -15px;
  margin-top: -20px;
  background-color: var(--secondary);
  /* position: absolute;
  bottom: 0;
  left: 0; */

  span {
    float: left;
    width: 100%;
    display: block;

    padding: 2px 5px;
    text-align: center;
    font-size: $font-size-base - 0.1;
    border-radius: 3px;
  }

  &:hover {
    background-color: var(--primary);
  }
}

.removeWishList {
  margin-top: 0;
}

$poster-wide-mobile: 50%;

.posterWide {
  /* width: $poster-wide-mobile;
  min-width: $poster-wide-mobile;
  max-width: $poster-wide-mobile;
  padding: 5px 5px 10px 5px; */
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  /* max-height: calc(0.56471 * 100%); */

  .posterThumbnail {
    padding-bottom: calc(0.56471 * 100%);
  }

  @include media-breakpoint-up(lg) {
    .posterInfo {
      padding-top: 30px;
    }
  }
}
