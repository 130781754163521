@import "../../../assets/styles/mixins";



html,
body {
  overflow-x: hidden;
}

.header {
  background-color: var(--body-bg);
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  height: $headerHeight;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;

  .branding {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .mobile-menu-toggle-btn {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    height: $headerHeight - 10;
    margin-left: -10px;
  }

  .branding-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;

    img {
      max-height: 40px;
    }
  }

  @include media-breakpoint-up(xl) {
    .container-fluid {
      padding-left: 50px;
      padding-right: 50px;
    }

    .branding-link {
      padding-left: 0;
    }
  }
}

.main-nav-block {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  transform: translateX(-250%);
  transition: all 0.3s ease;

  &.show-menu {
    transform: translateX(0);
  }

  .mobile-nav-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 2;
  }

  .main-nav-list-block {
    position: relative;
    height: 100%;
    overflow-y: auto;
    background-color: var(--secondary);
    z-index: 4;
    width: 75%;
    max-width: 400px;
    box-shadow: 5px 0 30px rgba(0, 0, 0, 0.5);
  }

  .main-nav-list {
    padding: 15px 0;

    li {
      float: left;
      width: 100%;
    }

    a {
      color: var(--gray);
      padding: 10px 25px;
      display: flex;
      border-radius: 5px;
      font-weight: 600;
      font-size: 17px;

      &:hover {
        text-decoration: none;
        color: white;
      }

      &.activeLink {
        color: #ff304f;
      }
    }
  }

  @include media-breakpoint-up(md) {
    position: relative;
    left: auto;
    top: auto;
    bottom: auto;
    right: auto;
    transform: translateX(0);
    box-shadow: none;

    .main-nav-list-block {
      box-shadow: none;
      background-color: transparent;
      overflow: visible;
      width: 100%;
      max-width: inherit;
    }

    .main-nav-list {
      padding: 0;
      display: flex;
      align-items: center;

      li {
        width: auto;
      }

      a {
        padding-top: 12px;
        padding-bottom: 11px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .main-nav-list {
      a {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
}

.closeMobileNavBtn {
  color: white;
  cursor: pointer;
}

.header-right-actions-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding-top: 6px;
  padding-bottom: 6px;

  .headerSubscribeBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    background-color: var(--tertiary);
    color: var(--dark);
    border-radius: var(--border-radius);
    font-size: $font-size-base - 0.2;
    text-transform: uppercase;
    height: 30px;
    font-weight: 500;

    &:hover {
      text-decoration: none;
      background-color: var(--primary);
      color: white;
    }
  }

  .headerLoginBtn {
    margin-left: 10px;
    height: 30px;
    border-radius: var(--border-radius);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--gray);
    font-size: $font-size-base - 0.1;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
      color: white;
    }

    &.activeLink {
      color: white;
    }
  }
}

.mobileHeaderSearchBtn {
  margin-right: 5px;

  .searchIcon {
    display: block;
  }

  .searchCloseIcon {
    display: none;
  }

  &.mobile-search-active {
    .searchIcon {
      display: none;
    }
    .searchCloseIcon {
      display: block;
    }
  }

  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
  }
}

.header-search-block {
  position: fixed;
  left: 0;
  top: $headerHeight - 5;
  width: 100%;
  /* padding-left: 10px;
  padding-right: 10px; */
  padding-bottom: 10px;
  transform: translateX(-150%);
  transition: all 0.3s ease;
  opacity: 0;
  background-color: var(--dark);
  box-shadow: 0 3px 10px var(--dark);

  &.show-mobile-search {
    transform: translateX(0);
    opacity: 1;
  }

  .searchInput {
    background-color: transparent;
    border-color: transparent;
    border-bottom: 1px solid var(--gray);
    color: white;
    float: left;
    width: 100%;
    padding-right: 30px;
    height: 36px;
  }

  .headerSearchBtn {
    position: absolute;
    right: 10px;
    top: 5px;
    padding: 0;
    opacity: 0.7;
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    top: auto;
    left: auto;
    max-width: 200px;
    margin-right: 20px;
    padding: 0;
    transform: translateX(0);
    opacity: 1;
    box-shadow: none;

    .headerSearchBtn {
      right: 5px;
    }
  }
}


.header-my-account-toggle-btn {
  color: white;
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    color: #aaa;
  }

}

.header-my-account {
  position: relative;

  .my-account-list {
    position: absolute;
    right: -5px;
    top: 40px;
    background-color: var(--secondary);
    width: 160px;
    padding-top: 10px;
    border-radius: 5px;

    &::before {
      content: "";
      position: absolute;
      right: 28px;
      top: 0px;
      border: 8px solid transparent;
      border-top-color: var(--dark);
      display: block;
    }
  }

  .my-account-item-link {
    color: white;
    display: block;
    padding: 7px 15px;
    width: 100%;
    text-align: left;

    &:hover {
      background-color: var(--dark);
      text-decoration: none;
    }
  }

  @include media-breakpoint-up(lg) {
    .my-account-list {
      &::before {
        right: auto;
        left: 50%;
      }
    }
  }
}

.my-account-active-link {
  color: var(--primary);
}



.my-account-toggle-btn {
    display: block;
    color: white;
    padding: 5px 17px 5px 10px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 18px;
      border: 5px solid transparent;
      border-top-color: #aaa;
      transition: all 0.2s ease;
    }

    &:focus,
    &:focus-within,
    &:active,
    &:hover {
      outline: none;
      box-shadow: 0 0 0 transparent;
    }

    &.open-menu {
      &::after {
        transform: rotate(180deg);
        top: 12px;
      }
    }

    .profile-user-icon {
      width: 30px;
      height: 30px;
      fill: #aaa;
    }

    .profile-user-name {
      display: none;
      color: white;
    }

    @include media-breakpoint-up(lg) {
      .profile-user-name {
      display: inline-block;
      color: white;
    }
    }
  }