@import "../../../assets/styles/mixins";

$poster-desktop-height: 400px;

.movie-detail-info {
  position: relative;

  .movie-poster {
    position: relative;
    padding-bottom: calc(100vw * 0.5625);
    overflow: hidden;
    z-index: 2;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 100%;
      height: 100px;
      background: rgb(16, 27, 41);
      background: linear-gradient(0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0) 100%);
      z-index: 5;
    }
  }

  .movie-action-block {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 7;
  }

  .movie-info-block {
    position: relative;
    top: 0px;
    z-index: 5;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .watch-movie-btn {
    color: white;
    font-weight: 600;
    font-size: $font-size-base + 0.4;

    .movie-play-btn-icon {
      margin-right: 5px;
    }
  }

  .movie-type {
    display: flex;
    justify-content: flex-start;
  }

  .premium-block {
    display: inline-flex;
    padding: 3px 7px 5px 7px;
    font-size: $font-size-base - 0.2;
    color: #010101;
    background-color: var(--tertiary);
    line-height: 1;
    border-radius: 5px;
    text-transform: uppercase;
  }

  .premium-icon {
    margin-right: 5px;
  }

  .movie-title {
    font-size: $font-size-base + 0.5;
    font-weight: 700;
    color: white;
  }

  .movie-desc {
    font-size: $font-size-base - 0.09;
    max-height: 86px;
    overflow: hidden;
  }

  @include media-breakpoint-up(md) {
    .movie-poster {
      /* max-width: 768px; */
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  @include media-breakpoint-up(lg) {
    background-color: var(--secondary);
    border-radius: 3px;
    overflow: hidden;

    .movie-poster {
      display: flex;
      justify-content: flex-end;
      background-color: var(--secondary);
      padding-bottom: $poster-desktop-height;
      max-height: $poster-desktop-height;
      border-radius: 5px;

      img {
        max-height: 400px;
        max-width: 760px;
        left: auto;
        right: 0;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: auto;
        right: 610px;
        width: 150px;
        height: 100%;
        z-index: 7;
        display: block;
        background: #2A2A2A;
        background: linear-gradient(90deg, #2A2A2A 30%, rgba(0, 0, 0, 0) 100%);
      }
    }

    .movie-info-block {
      position: absolute;
      left: 30px;
      top: 30px;
      max-width: 500px;
      /* background-color: var(--primary); */
    }
  }
}

@import "../../../assets/styles/slick";