.slick-slider {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  .slick-list,
  .slick-slide {
    float: left;
    width: 100%;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    z-index: 6;
    font-size: 0;
    background-color: transparent;
    border-radius: 100px;
    margin-top: -20px;
    border: none;
    display: none !important;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      position: absolute;
      top: 12px;
      width: 15px;
      height: 15px;
      border-radius: 2px;
      border-top: 3px solid var(--gray);
      border-right: 3px solid var(--gray);
      transform: rotate(45deg);
    }

    &:hover {
      background-color: transparent;
    }

    &.slick-next {
      right: 0px;

      &::before {
        left: 10px;
      }
    }

    &.slick-prev {
      left: 0px;
      &::before {
        transform: rotate(-135deg);
        left: 15px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-left: 0;
    padding-right: 0;

    &:hover {
      .slick-arrow {
        display: flex !important;
        background-color: var(--secondary);
      }
    }
  }
}

.homeBanners {
  .slick-slide {
    padding-left: 5px;
    padding-right: 5px;
  }

  @include media-breakpoint-up(lg) {
    .slick-slide {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.playlist-video-list {
  position: relative;

  .slick-slide {
    padding-left: 5px;
    padding-right: 5px;
  }

  .slick-arrow {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    .slick-arrow {
      position: absolute;
      top: 0%;
      height: 0;
      width: 65px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-top: 0;
      border-radius: 0px;

      &::before {
        top: 50%;
        margin-top: -10px;
      }

      &.slick-disabled {
        display: none !important;
      }

      &.slick-prev {
        left: -65px;
        background-color: rgba(0, 0, 0, 0.5);
        background: rgb(14, 13, 11);
        background: linear-gradient(
          90deg,
          rgba(14, 13, 11, 1) 30%,
          rgba(0, 0, 0, 0) 100%
        );

        &::before {
          left: 20px;
        }
      }

      &.slick-next {
        right: -65px;

        background: rgb(14, 13, 11);
        background: linear-gradient(
          270deg,
          rgba(14, 13, 11, 1) 30%,
          rgba(0, 0, 0, 0) 100%
        );

        &::before {
          left: 20px;
        }
      }
    }
  }
}
