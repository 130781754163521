$headerHeight: 60px;

input,
button,
select,
a {
  &:focus,
  &:active {
    outline: none;
    outline-color: transparent;
    box-shadow: none;
  }
}

.full-width {
  float: left;
  width: 100%;
}

.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: white;
}

.page {
  margin-top: $headerHeight;
  color: var(--gray);
  font-size: $font-size-base;
  min-height: calc(100vh - #{$headerHeight});
  background-color: var(--body-bg);
  @include media-breakpoint-up(xl) {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.playlist-row {
}

.playlist-heading {
  font-size: $font-size-base + 0.5;
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: white;
  margin-bottom: 10px;

  .playlist-heading-title {
    float: left;
    color: white;
    max-width: calc(100% - 50px);
    text-decoration: none;


  }

  a.playlist-heading-title {

    &:hover {
      text-decoration: none;
      color: var(--blue);
    }
  }

  .playListMoreBtn {
    float: right;
    font-size: $font-size-base;
    color: var(--blue);
    width: 50px;
    padding-top: 5px;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}

.text-truncate {
  @include text-truncate();
}

.meta-list {
  display: flex;
  @include text-truncate;
  align-items: center;
  flex-wrap: nowrap;

  .each-meta-item {
    display: inline-flex;
    align-items: center;
    font-size: $font-size-base - 0.1;
    padding-left: 10px;
    padding-right: 7px;
    color: $gray;
    position: relative;
    -webkit-font-smoothing: antialiased;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 10px;
      width: 3px;
      height: 3px;
      border-radius: 10px;
      background-color: var(--gray);
    }

    &:first-child {
      padding-left: 0;

      &::before {
        display: none;
      }
    }
  }
}


.min-height {
  min-height: calc(100vh - 150px);

  @include media-breakpoint-up(md) {
    min-height: calc(100vh - 200px);
  }
}

.password-input-block {
  position: relative;
  display: flex;
  align-items: center;

  .password-input {
    float: left;
    width: calc(100% - 40px);
  }

  .passwordViewToggle {
    border: none;
    width: 40px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

  }
}

.ml-05 {
  margin-left: 5px;
}

.mr-05 {
  margin-right: 5px;
}

.mt-05 {
  margin-top: 5px;
}

.mb-05 {
  margin-bottom: 5px;
}

input,
button,
select {
  appearance: unset;
}

select {
  background-image:
    linear-gradient(45deg, transparent 50%, #ccc 50%),
    linear-gradient(135deg, #ccc 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}