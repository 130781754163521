@import "../../../assets/styles/mixins";


.login-input {
   background-color: transparent;
   border: none;
   color: white;
   padding-left: 3px;
   font-size: $font-size-base;

   &:focus,
   &:hover,
   &:active {
      border: none;
      background-color: transparent;
      color: white;
      box-shadow: 0 0 0 transparent;
   }
}


.loginHeading {
   color: white;
   font-size: $font-size-base + 0.5;
   font-weight: 600;
}

.login-back-btn {
   color: var(--blue);

   &:hover {
      color: white;
      text-decoration: none;
   }
}

.loginBg {

}

.toggle-other-login-methods-btn {
   border: 1px solid var(--dark);
   /* background-color: var(--secondary); */
   background-color: transparent;
   padding: 10px 20px;
   text-align: center;
   color: var(--white);
   font-size: $font-size-base + 0.1;
   width: 100%;
   max-width: 340px;

   &:hover {
      background-color: var(--dark);
      color: white;
   }

}

.login-form {
   width: 390px;
}

.phone-country-block {
   border-bottom: 1px solid var(--gray);

   .phone-country-code {
      border: none;
      height: 40px;
      background-color: transparent;
      color: white;
      font-size: $font-size-base;
      padding: 0 8px;
      width: 60px !important;
      padding-left: 0;
      float: left;
   }

   .phone-input {
      background-color: transparent;
      border: none;
      color: white;
      font-size: $font-size-base + 0.3;
      float: left;
      width: calc(100% - 60px);

      &:hover,
      &:focus,
      &:focus-within,
      &:active {
         border: none;
         background-color: transparent;
         box-shadow: 0 0 0 transparent;
      }

      &:-internal-autofill-selected {
         background-color: transparent;
      }


      &::-webkit-input-placeholder { /* Edge */
         font-size: $font-size-base + 0.1;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
         font-size: $font-size-base + 0.1;
      }

      &::placeholder {
      font-size: $font-size-base + 0.1;
      }
   }
}

.form-error {
   color: #f85959;
}

.submit-phone-btn {
   font-size: $font-size-base + 0.1;
}

.otp-title {
   color: white;
   font-size: $font-size-base + 0.5;
}

.otp-block {
   max-width: 250px;
   border-bottom: 1px solid var(--gray);
   clear: both;
   text-align: center;
}

.otp-input {
   font-size: $font-size-base + 1.5;
   letter-spacing: 15px;
   text-align: center;

   &:focus {
      background-color: transparent;
      color: white;
   }
}

.resend-btn {
   background-color: transparent;
   color: white;
   line-height: 1.2;
   margin-top: 5px;

   &:disabled,
   &.disabled {
      background-color: var(--secondary);
      color: $gray;
   }
}


.social-logins-list {
   li {
      float: left;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
   }
}

.social-login {
   width: 320px;
   background-color: var(--dark);
   height: 44px;
   color: white;
   line-height: 1.1;
   font-size: $font-size-base + 0.1;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   padding-left: 30px;
   padding-right: 30px;

   .social-login-icon {
      margin-right: 15px;
   }

   &:hover {
      background-color: #000;
      color: white;
   }

   /* &.facebook {
      background-color: #000;
   }

   &.google {
      background-color: var(--gray);
      color: #333;
   } */
}

.thanks-message {
   padding: 40px 25px;

   .thanks-title {
      font-size: $font-size-base + 0.6;
      font-weight: 700;
      color: white;
      line-height: 1.1;
   }

   .thanks-info {
      color: white;
      font-size: $font-size-base + 0.2;
   }
}
