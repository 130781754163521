@import "../../../assets/styles/mixins";

$mobileBannerHeight: calc(100vw * 0.5625);
$desktopBannerHeight: 400px;

.banner-item {
  position: relative;
  border-radius: $border-radius;
  height: $mobileBannerHeight;
  overflow: hidden;
  text-decoration: none;
  color: var(--gray);

  .banner-image {
    height: $mobileBannerHeight;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    background-color: #0e0d0b;
    border-radius: $border-radius;

    img {
      /* max-height: $desktopBannerHeight; */
      width: 100%;
      height: 100%;
    }
  }

  .banner-content {
    display: none;
  }

  @include media-breakpoint-up(md) {
    height: calc(100vw * 0.47);

    .banner-image {
      height: calc(100vw * 0.47);
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: stretch;
    height: 100%;
    height: $desktopBannerHeight;

    .banner-image {
      height: $desktopBannerHeight;

      img {
        width: auto;
        max-height: $desktopBannerHeight;
      }
    }
    .banner-content {
      display: block;
      position: relative;
      height: $desktopBannerHeight;
      z-index: 4;
      float: left;
      width: 63%;
      padding: 60px 25% 30px 35px;
      background: rgb(16, 27, 41);
      background: linear-gradient(90deg, #2A2A2A 80%, rgba(0, 0, 0, 0) 100%);
      color: var(--gray);
      border-radius: $border-radius;
    }

    .banner-title {
      font-size: $font-size-base + 1.1;
      color: white;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .banner-meta-info {
      font-size: $font-size-base - 0.05;
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: var(--gray);

      .each-meta-info {
        display: inline-block;
        padding: 0 10px 0 15px;
        position: relative;
        color: var(--gray);

        &::before {
          content: "";
          width: 5px;
          height: 5px;
          background-color: var(--gray);
          border-radius: 100px;
          position: absolute;
          left: 0;
          top: 8px;
        }

        &:first-child {
          padding-left: 0;

          &::before {
            display: none;
          }
        }
      }
    }

    .banner-meta-desc {
      color: var(--gray);
      max-height: 148px;
      overflow: hidden;
    }
  }
}

.playlist-video-list {
  .slider {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
  }

  .posterPotrait {
    padding-right: 7px;
  }

  @include media-breakpoint-up(lg) {
    .slider {
      flex-wrap: wrap;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      overflow-x: visible;
    }
  }
}

@import "../../../assets/styles/slick";
